/* Reset CSS */

/* Box sizing */
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

/* Remove default margin and padding */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  padding: 0;
}

/* Set base font size and line height */
body {
  font-size: 16px;
  line-height: 1.5;
  background-color: #f5f5f5;
  font-family: "Source Sans 3", sans-serif;
}

/* Remove list styles */
ul,
ol {
  list-style: none;
}

/* Remove underlines from links */
a {
  text-decoration: none;
}

/* Remove outline on active elements */
button,
a,
input,
textarea,
select {
  outline: none;
}

/* Normalize form elements */
button,
input,
select,
textarea {
  font-family: inherit;
}

/* Remove user agent styling */
button,
input {
  border: none;
  background: none;
}

/* Set default font color and background */
body {
  color: #333;
  background-color: #fff;
}

/* Set default font family */
body,
input,
button,
select,
textarea {
  font-family: Arial, sans-serif;
}

/* Remove default button styling */
button {
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  border: none;
}

/* Reset CSS End */
.grid-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  /* Default to 1 column */
  gap: 20px;
  /* Gap between grid items */
}

.grid-card {
  padding: 20px;
  text-align: center;
}

/* Responsive Styles */
@media screen and (min-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
    /* 2 columns for larger screens */
  }
}

@media screen and (min-width: 1024px) {
  .grid-container {
    grid-template-columns: repeat(4, 1fr);
    /* 4 columns for even larger screens */
  }
}

.grid-3-cols-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  /* Default to 1 column */
  gap: 20px;
  /* Gap between grid items */
}

.grid-card {
  padding: 20px;
  text-align: center;
}

/* Responsive Styles */
@media screen and (min-width: 768px) {
  .grid-3-cols-container {
    grid-template-columns: repeat(3, 1fr);
    /* 2 columns for larger screens */
  }
}

@media screen and (min-width: 1024px) {
  .grid-3-cols-container {
    grid-template-columns: repeat(3, 1fr);
    /* 4 columns for even larger screens */
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

a {
  color: #890E57;
}
