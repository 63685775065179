.form-transition-enter {
    opacity: 0;
    transform: translateY(-20px);
}

.form-transition-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
}

.form-transition-exit {
    opacity: 1;
    transform: translateY(0);
}

.form-transition-exit-active {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 300ms, transform 300ms;
}