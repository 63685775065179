.products-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* Default to 1 column */
    gap: 20px;
    /* Gap between grid items */
}

.product-card {
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

.product-card:hover {
    transform: scale(1.05);
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
}

/* Responsive Styles */
@media screen and (min-width: 768px) {
    .products-container {
        grid-template-columns: repeat(2, 1fr);
        /* 2 columns for larger screens */
    }

    .product-card.double-column {
        grid-column: span 2;
        /* Span 2 columns */
    }
}

@media screen and (min-width: 1024px) {
    .products-container {
        grid-template-columns: repeat(3, 1fr);
        /* 4 columns for even larger screens */
    }
}