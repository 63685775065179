.scroll-to-top-button {
    position: fixed;
    bottom: 80px;
    right: 20px;
    background-color: transparent;
    color: white;
    border: none;
    padding: 10px 10px 6px;
    border-radius: 5px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
    transform: translateY(20px);
}

.scroll-to-top-button.visible {
    opacity: 1;
    transform: translateY(0);
}